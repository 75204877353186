@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    @apply flex flex-col overflow-hidden rounded-md bg-white/80 shadow-md backdrop-blur-lg;
  }

  .high-contrast-theme .card {
    @apply border border-indigo-800 bg-white shadow-none;
  }

  .card-image {
    @apply h-32 w-full object-cover object-center;
  }

  .card-content {
    @apply p-4;
  }

  .card-actions {
    @apply mt-auto flex flex-row justify-end p-4;
  }

  .card-tags {
    @apply flex flex-row flex-wrap gap-2 p-4 pt-0;
  }

  .card-hoverable {
    @apply transition-all hover:-translate-y-0.5 hover:bg-white/90 hover:shadow-lg;
  }
}

@layer utilities {
  .bg-colorshift-dark {
    @apply animate-bg-position bg-gradient-to-br from-blue-800 to-purple-800 bg-[length:400%];
  }

  .bg-colorshift-light {
    @apply animate-bg-position bg-gradient-to-br from-blue-500 to-purple-500 bg-[length:400%];
  }

  .text-colorshift {
    @apply inline-block animate-bg-position bg-gradient-to-br from-sky-600 via-purple-600 to-blue-600 bg-[length:300%] bg-clip-text py-1 text-transparent;
  }

  .perspective-right {
    will-change: transform;
    transform: perspective(1000px) rotateY(-15deg);
  }

  .perspective-left {
    will-change: transform;
    transform: perspective(1000px) rotateY(15deg);
  }
}
